import React from "react";
import { Button, Card, Container, Nav, Row } from "react-bootstrap";

// import { ArrowRight } from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const ChoosingAPuppy = () => {
  const getLink = useStore((state) => state.getLink);

  const services = [
    {
      cardHeaderTitle: "Choosing a Perfect Dog",
      image: "youChooseAPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          The thought of raising a new puppy on your own is overwhelming. You
          want a dog that will fit perfectly with your lifestyle. Your life is
          hectic, you’ve got a full time job, maybe raising a young family, you
          like to travel or you’ve just retired. Peace of Mind Puppy provides a
          stress free experience to add that awesome dog to your life.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "The Process Begins with a Consultation",
      image: "beginsWithAConsult",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          We think it’s important that you know who you will be working with.
          Building a rapport is extremely important to us.
          <br />
          <br />
          Our conversation begins with simple questions and leads into an in
          depth conversation, which will give us a better idea of who you are
          and what your perfect dog would be.
          <br />
          <br />
          We want to have a great working relationship with you. It starts the
          journey to choose, teach and raise your trained puppy.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Beginning the Process",
      image: "findingTheBestBreed",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Start the process of acquiring your perfect family companion by
          choosing your puppy. There are two routes to your puppy; you can
          purchase one of our available puppies that have already started their
          training or if we don’t have what you’re looking for you can contact
          us to discuss what you’re looking for and we work together to get your
          dream puppy then we train it for you.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Option One: Pick One of Our Available Puppies",
      image: "choosingABreeder",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Page through our available puppies to see if one catches your eye.
          These guys are hand picked from breeders to go through the program to
          meet the standard of a Buy A Trained Puppy. Our available puppies are
          all working towards potty trained, crate trained, learned calmness,
          good listening skills and good house manners. They are exposed to as
          much as possible to create a steady temperament. The biggest advantage
          of choosing one of our available puppies is that you can meet them and
          see if they would be a good match for you. Cost depends on the puppy
          you choose.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Option Two: We Work Together to Find Your Dream Puppy",
      cardButtonPath: getLink(pathTitles.YOURPUPPYSTRAINING),
      cardButtonMsg: "Your Puppy's Training",
      image: "choosingABreeder",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          This is the best route to find the dog you’ve always dreamed of
          having. Exactly what you want trained to the same standard. We will
          discuss your lifestyle, what you’re looking for and what you envision
          yourself doing with your dog. Then we start looking to find the right
          puppy that fits the bill for you. As we look for your forever friend
          we communicate about options and are here to advise every step of the
          way. Pros and cons of each breed, breeder, and individual puppy. Once
          your puppy is selected (yay!) at the breeder we prepare for your puppy
          coming to begin it’s education. Puppy comes straight to us from the
          breeder and is taught all the basics to make them a Buy A Trained
          Puppy. This process gets you exactly what you dream of having but can
          take longer than choosing an available puppy (timeline totally
          dependent on the breeder we choose). The cost for this option is Cost
          at breeder + $3500 for training.
        </Card.Body>,
      ],
    },
  ];
  return (
    <>
      <Container className="home_services_offered_container small_container clear">
        <Row className="justify-content-center first_row">
          {services.map((service, i) => (
            <React.Fragment key={`card${i}`}>
              <span
                className={`home_services_offered_image ${service.image}`}
              />
              <Card className="services_offered card_inner">
                <Card.Body className="parent_body">
                  <Card.Header className={`services_offered card_header`}>
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body) => body)}
                  {service.cardButtonPath && (
                    <Button
                      className="services_offered card_button cedar"
                      as={Nav.Link}
                      href={service.cardButtonPath}
                    >
                      {service.cardButtonMsg}
                      {/* <ArrowRight style={{width: "50px"}} /> */}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </>
  );
};
