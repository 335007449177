import React from "react";
import { Card, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Components
import { ContactUsForm } from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const ContactUs = () => {
  const getLink = useStore((state) => state.getLink);
  // const overlay = {
  //   where: "contactUs",
  //   title: "How Can We Help?",
  //   caption:
  //     "We all dream of having dogs respond like this; Justine can help you achieve it.",
  // };
  const info = {
    routes: {
      Home: "/",
      "Contact the Team": getLink(pathTitles.CONTACTUS),
    },
  };
  return (
    <>
      {/* <PageOverLay overlay={overlay} /> */}
      <Container
        className="small_container common_container margin_bottom"
        style={{ marginBottom: "-15rem" }}
      >
        <Row>
          <Card.Header className="common_header">
            {Object.keys(info.routes).map((routeName, i) => {
              return (
                <>
                  <Link
                    key={`${info.routes[routeName]}_${i}`}
                    to={info.routes[routeName]}
                  >
                    {routeName}
                  </Link>
                  {i < Object.keys(info.routes).length - 1 && (
                    <>&nbsp;/&nbsp; </>
                  )}
                </>
              );
            })}
          </Card.Header>
        </Row>
      </Container>
      <ContactUsForm />
    </>
  );
};
