import React from "react";

// Components
import {
  // BlogArticles,
  HomeAbout,
  HomeOverLay,
  HomeServicesOffered,
  Interested,
} from "../Components";

export const Home = () => {
  return (
    <>
      <HomeOverLay />
      <HomeAbout />
      <HomeServicesOffered />
      {/* <BlogArticles /> */}
      <Interested />
    </>
  );
};
