import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

// Components
import { CarouselArrows, CarouselContent, CarouselDots } from ".";

export const Carousel = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const len = images().length - 1;
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex(activeIndex === len ? 0 : activeIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [activeIndex]);

  return (
    <div className="slider_container">
      <CarouselContent activeIndex={activeIndex} sliderImage={images} />
      <CarouselArrows
        prevSlide={() =>
          setActiveIndex(activeIndex < 1 ? len : activeIndex - 1)
        }
        nextSlide={() =>
          setActiveIndex(activeIndex === len ? 0 : activeIndex + 1)
        }
      />
      <CarouselDots
        activeIndex={activeIndex}
        sliderImage={images}
        onclick={(activeIndex) => setActiveIndex(activeIndex)}
      />
    </div>
  );
};

Carousel.propTypes = {
  images: PropTypes.func,
};
