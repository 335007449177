import React from "react";
import { Button, Card, Container, Nav, Row } from "react-bootstrap";

// import { ArrowRight } from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const PuppyDelivery = () => {
  const getLink = useStore((state) => state.getLink);

  const services = [
    {
      cardHeaderTitle: "Puppy Delivery and New Owner Training",
      image: "youChooseAPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          We personally deliver your dog to your home. Our success is insured
          through the delivery and training with you, the new owner.
          <br />
          <br />
          Teaching you exactly what your puppy has learned and how to maintain
          their current behavior is just as important as your puppy’s education.
          Each puppy includes 6 lessons for their new owners with trainer
          Justine Ramey to learn exactly how to continue successfully with their
          new best friend.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Delivery",
      image: "beginsWithAConsult",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          During lessons we will cover all of the essential knowledge you need
          to know for a happy lifetime with your perfect family companion.
          <br />
          <br />
          <Card.Title>Within 2 hour of Indianapolis :</Card.Title>
          <br />
          <br />
          If you live within a few hours of Indy we arrange to do lessons while
          your puppy is getting its education. If you’re in the Indianapolis
          Metro area you can even take your puppy home on the weekend in the
          last 2 weeks of training. When your puppy is ready to go home (based
          on their potty training) you will have completed enough lessons to
          have you prepared for the big day. Now the puppy stays home and you
          use what you learned to have the best life with your new friend
          possible!
          <br />
          <br />
          <Card.Title>Out of State :</Card.Title>
          <br />
          <br />
          If you’re far away from Indy don’t worry! Trainer Justine Ramey has
          hand delivered trained puppies across the country. If you’re too far
          away from Indianapolis to do lessons while your puppy is being
          educated we do one zoom lesson during puppy’s training and then
          arrange a weekend (3 days) to deliver your puppy at the end of 8 weeks
          of training. Justine will fly or drive your puppy out personally. On
          day one Justine will spend the morning educating you on livability
          factors; crate training, potty training, how to set boundaries. Puppy
          spends the afternoon and evening with you. Day Two Justine will come
          back in the morning to discuss how your evening went and cover more
          advanced material including obedience. You will spend the afternoon
          with your puppy working on what was discussed in the past two days. On
          day three Justine will spend the morning with you one last time to
          cover any loose ends and questions. Now the puppy stays home and you
          use what you learned to have the best life with your new friend
          possible!
          <br />
          <br />
          Cost for delivery: dependent upon your location.
          <ol type="i">
            <li>
              Flat fee for gas + 2 nights in a nearby hotel (within 6-8 hours of
              Indy)
            </li>
            <li>Cost of Flight + 2 nights in a nearby hotel</li>
          </ol>
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "After Delivery",
      image: "findingTheBestBreed",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          After Delivery we are always here to assist you. Please contact us the
          moment you need help, have questions or concerns.
          <br />
          <br />
          And we always love to get updates!
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Also included with Each Puppy",
      cardButtonPath: getLink(pathTitles.VIEWPUPPIES),
      cardButtonMsg: "View Puppies...",
      image: "goHomePackage",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          <ul>
            <li>
              6 Lessons for owners with Trainer Justine Ramey (Indy’s In Home
              Dog Trainer)
            </li>
            <li>Size appropriate crate</li>
            <li>Medium sized bag of size appropriate puppy food</li>
            <li>Rabies, Bordetella, DHPP</li>
            <li>Place mat/cot</li>
            <li>Homework Handouts</li>
            <li>Other essentials that we think you may need</li>
          </ul>
        </Card.Body>,
      ],
    },
  ];
  return (
    <>
      <Container className="home_services_offered_container small_container clear">
        <Row className="justify-content-center first_row">
          {services.map((service, i) => (
            <React.Fragment key={`card${i}`}>
              <span
                className={`home_services_offered_image ${service.image}`}
              />
              <Card className="services_offered card_inner">
                <Card.Body className="parent_body">
                  <Card.Header className={`services_offered card_header`}>
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body) => body)}
                  {service.cardButtonPath && (
                    <Button
                      className="services_offered card_button cedar"
                      as={Nav.Link}
                      href={service.cardButtonPath}
                    >
                      {service.cardButtonMsg}
                      {/* <ArrowRight style={{width: "50px"}} /> */}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </>
  );
};
