import React from "react";
import { Button, Card, Container } from "react-bootstrap";

import { YouTubeEmbed } from "../Common";

export const Interested = () => {
  return (
    <Container className="interested_container small_container clear">
      <Card className="interested card_inner">
        <Card.Title className="title">
          Interested in Owning a Buy A Trained Puppy?
        </Card.Title>
        <YouTubeEmbed embedId="ymrk4gEXdp0" />
        <Button className="card_button cedar">Get A Puppy!</Button>
      </Card>
    </Container>
  );
};
