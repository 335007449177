import PropTypes from "prop-types";
import React from "react";

export const CarouselContent = ({ activeIndex, sliderImage }) => {
  const images = sliderImage();
  return (
    <section>
      {images.map((slide, index) => (
        <div
          key={index}
          className={`${activeIndex === index ? "slides active" : "inactive"}`}
        >
          <img className="slide_image" src={slide} alt="" />
        </div>
      ))}
    </section>
  );
};

CarouselContent.propTypes = {
  activeIndex: PropTypes.number,
  sliderImage: PropTypes.func,
};
