import React from "react";
import { Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";

export const HomeServicesOffered = () => {
  const getLink = useStore((state) => state.getLink);
  const puppies = useStore((state) => state.puppies);

  const services = [
    {
      cardHeaderTitle: "1. You Choose a Puppy",
      cardButtonPath: getLink(pathTitles.CHOOSINGAPUPPY), // chooseing your puppy
      cardButtonMsg: "Learn More",
      image: "youChooseAPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Choosing the breed of your new puppy is an important decision. As we
          get to know you better we can help you decide which breed fits you and
          your lifestyle best. We work with a network of reputable breeders to
          ensure your new puppy was born happy and healthy.
          <br />
          <br />
          From there, we share the journey into teaching and raising your Buy A
          Trained Puppy.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "2. We Train Your Puppy",
      cardButtonPath: getLink(pathTitles.YOURPUPPYSTRAINING),
      cardButtonMsg: "Learn More",
      image: "weTrainYourPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Every interaction with a new puppy is a training opportunity. We begin
          raising your puppy during their imprinting phase. During this phase
          your puppy’s brain is still developing, making them more
          impressionable and easily influenced. We train your puppy a
          combination of new skills including obedience, manners, setting
          boundaries and fostering healthy relationships.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "3. We Deliver Your Puppy. Then We Train You.",
      cardButtonPath: getLink(pathTitles.PUPPYDELIVERY),
      cardButtonMsg: "Learn More",
      image: "weDeliverYourPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          We allow a stress free transition by personally delivering your dog to
          you. While your dog gets comfortable with their new surroundings, we
          reinforce your confidence by teaching you about your dog’s training
          and our puppy raising philosophy. You and your new dog will spend the
          next few days learning together and getting better acquainted.
        </Card.Body>,
      ],
    },
  ];
  return (
    <>
      <Container className="home_services_offered_container small_container clear">
        <Row className="justify-content-center first_row">
          {services.map((service, i) => (
            <React.Fragment key={`card${i}`}>
              <span
                className={`home_services_offered_image ${service.image}`}
              />
              <Card className="services_offered card_inner">
                <Card.Body className="parent_body">
                  <Card.Header className={`services_offered card_header`}>
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body) => body)}
                  <Button
                    className="services_offered card_button cedar"
                    as={Nav.Link}
                    href={service.cardButtonPath}
                  >
                    {service.cardButtonMsg}
                  </Button>
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Row>
        <Row className="services_offered second_row">
          <Card.Title className="title">
            View Puppies We&apos;ve Trained
          </Card.Title>
          <div className="view_puppies">
            {puppies.map((puppy, i) => {
              while (i < 8) {
                return (
                  <Col
                    key={i}
                    sm={{ span: 10, offset: 1 }}
                    md={{ span: 5 }}
                    lg={{ span: 3 }}
                    className="col"
                  >
                    <Card
                      className="services_offered card_inner"
                      as={Link}
                      to={`trained-puppies-for-sale${puppy.profilePath}`}
                    >
                      <span
                        className={`home_services_offered_image profile ${puppy.image}`}
                      />
                      <Card.Header className="services_offered card_header">
                        {puppy.cardHeaderTitle}
                      </Card.Header>
                    </Card>
                  </Col>
                );
              }
            })}
          </div>
          <Button
            className="almond"
            as={Link}
            to={getLink(pathTitles.VIEWPUPPIES)}
          >
            View More...
          </Button>
        </Row>
      </Container>
    </>
  );
};
