import PropTypes from "prop-types";
import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { BorderAll, Carousel, ChevronLeft, ChevronRight } from "../Common";

export const PuppyProfile = (props) => {
  const { puppy, index } = props;
  const getLink = useStore((state) => state.getLink);
  const puppies = useStore((state) => state.puppies);

  const showLeft = index > 0 ? "show" : "";
  const showRight = index + 1 < puppies.length ? "show" : "";
  const pathLeft = showLeft
    ? `${getLink(pathTitles.VIEWPUPPIES)}${puppies[index - 1].profilePath}`
    : "";
  const pathRight = showRight
    ? `${getLink(pathTitles.VIEWPUPPIES)}${puppies[index + 1].profilePath}`
    : "";
  return (
    <>
      <Container className="puppy_profile_container small_container clear">
        <Card.Title className="title">{puppy.cardHeaderTitle}</Card.Title>
        <div className="icons">
          <Button
            className={`transparent chevron-left ${showLeft}`}
            as={Link}
            to={pathLeft}
          >
            {showLeft && <ChevronLeft className={`chevron-left ${showLeft}`} />}
          </Button>

          <Button
            className="transparent"
            as={Link}
            to={getLink(pathTitles.VIEWPUPPIES)}
          >
            <BorderAll className="border-all" />
          </Button>

          <Button
            className={`transparent chevron-right ${showRight}`}
            as={Link}
            to={pathRight}
          >
            <ChevronRight className={`chevron-right ${showRight}`} />
          </Button>
        </div>
        <div className="profile_container">
          <span className={`profile ${puppy.image}`} />
          <Card.Title className="title">
            {puppy.cardHeaderTitle} - Trained {puppy.breed} for Sale
          </Card.Title>
          {puppy.available ? (
            <>
              <Card.Subtitle className="title available_date">
                <Card.Subtitle className="title available">
                  AVAILABLE : &nbsp;
                </Card.Subtitle>
                <Card.Subtitle className="title available_date">
                  {puppy.availableDate}
                </Card.Subtitle>
              </Card.Subtitle>
            </>
          ) : (
            <Card.Subtitle className="title not_available">
              {puppy.availableDate}
            </Card.Subtitle>
          )}
          <Card.Body className="statistics">
            <ul>
              {puppy.breedLong ? (
                <li>Breed : {puppy.breedLong}</li>
              ) : (
                <li>Breed: {puppy.breed}</li>
              )}
              {puppy.breeder && <li>Breeder : {puppy.breeder}</li>}
              {puppy.projectedSize && (
                <li>Adult Size : {puppy.projectedSize}</li>
              )}
              {puppy.dob && <li>Date of Birth : {puppy.dob}</li>}
              {puppy.sex && <li>Sex : {puppy.sex}</li>}
            </ul>
          </Card.Body>
          <Card.Body className="body">{puppy.personality}</Card.Body>
          {puppy.saleUpdate && (
            <Card.Body className="body sold">{puppy.saleUpdate}</Card.Body>
          )}
          {puppy.carousel && <Carousel images={puppy.carousel} />}
          <Card.Body className="program_message">
            {puppy.cardHeaderTitle} has been raised and trained through our{" "}
            <Link to={getLink(pathTitles.YOURPUPPYSTRAINING)}>
              Buy a Trained Puppy Program.
            </Link>{" "}
          </Card.Body>
          <Card.Body className="delivery_message">
            Every Buy a Trained Puppy is delivered to your city with all
            necessary supplies, a certificate of health and liftime support.
            Several days of one-on-one private training for you and{" "}
            {puppy.cardHeaderTitle} in your home as you begin your life together
            are included.
          </Card.Body>
          <Card.Body className="price">
            <div className="message">
              Cost for {puppy.cardHeaderTitle} to be your trained puppy:
            </div>
            <div className={`${puppy.available ? "available" : "sold"}`}>
              {!puppy.available && "SOLD! Original"} Price: {puppy.price}
            </div>
          </Card.Body>
          <Card.Body className="out_of_state_message">
            Live in another state? Ask us about puppy delivery options.
          </Card.Body>
          <Card.Body className="out_of_state_message">
            *Additional fees may apply.
          </Card.Body>
        </div>
      </Container>
    </>
  );
};

PuppyProfile.propTypes = {
  puppy: PropTypes.object,
  index: PropTypes.number,
};
