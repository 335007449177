import PropTypes from "prop-types";
import React from "react";
import { Button } from "react-bootstrap";

// Components
import { ChevronLeft, ChevronRight } from ".";

export const CarouselArrows = ({ prevSlide, nextSlide }) => {
  return (
    <div className="arrows">
      <Button className={`transparent prev chevron-left`} onClick={prevSlide}>
        <ChevronLeft className={`chevron-left`} />
      </Button>
      <Button className={`transparent next chevron-right`} onClick={nextSlide}>
        <ChevronRight className={`chevron-right`} />
      </Button>
    </div>
  );
};

CarouselArrows.propTypes = {
  prevSlide: PropTypes.func,
  nextSlide: PropTypes.func,
};
