import React from "react";
import { Card, Container } from "react-bootstrap";

import { YouTubeEmbed } from "../Common";

export const HomeAbout = () => {
  return (
    <>
      <Container className="home_about_container small_container clear">
        <Card className="about_container_inner">
          <Card.Body>
            <Card.Title className="about_main_title">
              Trained Puppies for Sale
            </Card.Title>
            <Card.Body className="about_body">
              Buy A Trained Puppy provides a fun, well-mannered family
              companion. Your puppy is raised and trained to fit your lifestyle
              from the beginning. We insure instant happiness leaving out all
              the stress when adding a dog to your family.
            </Card.Body>
            <YouTubeEmbed embedId="HkCtjhbaY10" />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
