import React from "react";
import { Button, Card, Container, Nav, Row } from "react-bootstrap";

// import { ArrowRight } from "../Components";
// Store
import { pathTitles, useStore } from "../Store/Store";

export const YourPuppysTraining = () => {
  const getLink = useStore((state) => state.getLink);

  const services = [
    {
      cardHeaderTitle: "Your Puppy's Training",
      image: "youChooseAPuppy",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Training your pup is a combination of teaching new skills including
          manners, setting boundaries, fostering healthy relationships, as well
          as exposing your dog successfully to people, other dogs and
          desensitizing him or her to all environments.
          <br />
          <br />
          For example, we have the pup stay overnight with various people in
          different settings, which creates a dog for you who is comfortable and
          easy to live with because he/she easily adapts to your lifestyle.
          Because of this and exposures to other variables your dog readily
          bonds with you and your family.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle: "Imprinting Good Behavior",
      image: "beginsWithAConsult",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          Puppies come straight from the breeder to us to start their education
          towards the Buy A Trained Puppy standard. We deal with the howling in
          the crate, the potties on the floor, the chewed trim… We put in a lot
          of patience and dedication over (roughly) 8 weeks to help your puppy
          learn how to be the perfect companion.
          <br />
          <br />
          <Card.Title>Most important time for learning</Card.Title>
          <br />
          Puppies are regularly exposed to novel sights, sounds, smells,
          handling and experiences. This ensures a well adjusted puppy that can
          easily acclimate to new environments and stimuli. Your puppy is taught
          that sit and calmness get them everything they want from us,
          essentially eliminating demand barking, pawing, jumping, and other
          wildness to get what they want from us. Your puppy is also taught that
          the crate is a happy, safe place to be and that outside is the right
          place to relieve themselves. Your puppy will be starting* to ring a
          bell to ask to go outside to go potty. Your puppy will have already
          learned on us not to bite or chew inappropriately. Your puppy will
          still explore it’s environment and occasionally make mistakes but when
          it does so you will easily be able to correct them. Your puppy will be
          a good listener and eager to please.
          <br />
          <br />
          They will also know all their basic obedience including their name,
          sit, down, leave it, drop it, come when called, go potty and yes.
          Having your puppy spend these 8 weeks with us will set the foundation
          for having exactly the dog you have always dreamed of.
          <br />
          <br />
          *Indicating is a fancy word for asking to go outside. Although your
          puppy may be indicating for here with us it is vital that you follow
          the potty training protocol that we teach you to transfer this to your
          environment. Keep in mind that your puppy is still a baby and maturity
          is a factor. Don’t rely on your puppy to let you know. Keep a
          schedule, help them understand what you expect and the indicating will
          follow.
        </Card.Body>,
      ],
    },
    {
      cardHeaderTitle:
        "Testing in the Real World - Level Two ( +$5,000 ), The Teenage Stage",
      cardButtonPath: getLink(pathTitles.PUPPYDELIVERY),
      cardButtonMsg: "Delivering Your Puppy",
      image: "testingInTheRealWorld",
      cardBody: [
        <Card.Body key={Math.random()} className="services_offered card_body">
          We work to prevent any unwanted behavior by instilling boundaries and
          practicing obedience in high distraction areas. For example, we
          practice recalls when your dog is highly distracted maybe chasing
          something as common as a squirrel. We test and proof obedience in high
          traffic areas, around barking dogs, near playgrounds and within public
          places.
          <br />
          <br />
          As the dog moves through the adolescent stage we continue to challenge
          the dog by putting it in various situations. We think of each
          situation as a picture. What does that look like to the dog? How does
          that picture change from day to day, location to location. We are
          striving to teach a dog how to cope in every new and different
          environment or situation. We are raising a dog that isn’t dependent on
          continuous commands that dictate what he/she should and shouldn’t do.
          Instead, we want a dog that can make good choices. Our top priority is
          to have a well-balanced dog, who will be comfortable your home.
          <br />
          <br />
          We continue to reinforce and refine good manners making good behavior
          a habit for the dog. Throughout this time, the dog has been living
          with the trainer in his or her personal home. During this time, your
          dog will also spend a few days and nights staying with another family
          member or friend. Providing this opportunity encourages your dog to
          continue to be confident and comfortable in new surroundings. This
          ensures those habits and good manners will remain when he moves on to
          your home.
        </Card.Body>,
      ],
    },
  ];
  return (
    <>
      <Container className="home_services_offered_container small_container clear">
        <Row className="justify-content-center first_row">
          {services.map((service, i) => (
            <React.Fragment key={`card${i}`}>
              <span
                className={`home_services_offered_image ${service.image}`}
              />
              <Card className="services_offered card_inner">
                <Card.Body className="parent_body">
                  <Card.Header className={`services_offered card_header`}>
                    {service.cardHeaderTitle}
                  </Card.Header>
                  {service.cardBody.map((body) => body)}
                  {service.cardButtonPath && (
                    <Button
                      className="services_offered card_button cedar"
                      as={Nav.Link}
                      href={service.cardButtonPath}
                    >
                      {service.cardButtonMsg}
                      {/* <ArrowRight style={{width: "50px"}} /> */}
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </React.Fragment>
          ))}
        </Row>
      </Container>
    </>
  );
};
