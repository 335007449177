import React from "react";
import { Route, Routes } from "react-router-dom";

// Components
import { PuppyHome, PuppyProfile } from "../Components";
// Store
import { useStore } from "../Store/Store";

export const TrainedPuppiesForSale = () => {
  const puppies = useStore((state) => state.puppies);
  return (
    <>
      <Routes>
        <Route path="/" element={<PuppyHome />} />
        {puppies.map((puppy, i) => (
          <Route
            key={`${puppy.image}-${i}`}
            path={puppy.profilePath}
            element={<PuppyProfile puppy={puppy} index={i} />}
          />
        ))}
      </Routes>
    </>
  );
};
