import PropTypes from "prop-types";
import React from "react";

export const CarouselDots = ({ activeIndex, onclick, sliderImage }) => {
  const images = sliderImage();
  return (
    <div className="all_dots">
      {images.map((slide, index) => (
        <span
          key={index}
          className={`${activeIndex === index ? "dot active_dot" : "dot"}`}
          onClick={() => onclick(index)}
        ></span>
      ))}
    </div>
  );
};

CarouselDots.propTypes = {
  activeIndex: PropTypes.number,
  onclick: PropTypes.func,
  sliderImage: PropTypes.array,
};
