import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";

export const HomeOverLay = () => {
  const getLink = useStore((state) => state.getLink);
  return (
    <>
      <div className="home_overlay">
        <div className="home_overlay_slides">
          <div className="overlay_slide">
            <span className="overlay_slide_bg_wrap">
              <span className="overlay_slide_bg"></span>
            </span>
            <Container className="home_overlay_container clear">
              <Card className="overlay_container_inner">
                <Card.Body>
                  <Card.Body>
                    <h2>We Raise</h2>
                    <h2>and</h2>
                    <h2>Train Puppies</h2>
                    <h2>to</h2>
                    <h2>Complement Your Lifestyle.</h2>
                  </Card.Body>
                  <Button
                    className="home_overlay_button almond"
                    as={Link}
                    to={getLink(pathTitles.VIEWPUPPIES)}
                  >
                    View Puppies
                  </Button>
                </Card.Body>
              </Card>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};
