// import React, { useEffect } from "react";
import React from "react";
import { Container } from "react-bootstrap";
import { Route, Routes, useLocation } from "react-router-dom";

// Components
import {
  Footer,
  IconHeader,
  Logo,
  // Navigation,
  ToastComponent,
} from "./Components";
// Store
import { pathTitles, useStore } from "./Store/Store";
import {
  // About,
  ChoosingAPuppy,
  ContactUs,
  Home,
  NotFound,
  PuppyDelivery,
  TrainedPuppiesForSale,
  YourPuppysTraining,
} from "./Views";

function App() {
  const getLink = useStore((state) => state.getLink);
  // const checkServices = useStore((state) => state.checkServices);
  // const trainingDetails = useStore((state) => state.trainingDetails);
  // const getWindow = useStore((state) => state.getWindow);
  // useEffect(() => {
  //   checkServices(trainingDetails);
  //   getWindow();
  // }, []);

  let location = useLocation();
  let show = true;
  if (location.pathname != "/") show = false;
  return (
    <>
      <div id="blue_ink" className="wrapper bg-image">
        <ToastComponent />
        <IconHeader top_or_bottom="top" showLogo={!show} />
        {show && <Logo />}
        <div className="content-page">
          <Container fluid className="no-gutters main_container">
            {/* <span className="no_show_elm navigation_component">
              <Navigation mobile={false} expand={true} />
            </span> */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path={`${getLink(pathTitles.VIEWPUPPIES)}/*`}
                element={<TrainedPuppiesForSale />}
              />
              <Route
                path={getLink(pathTitles.CHOOSINGAPUPPY)}
                element={<ChoosingAPuppy />}
              />
              <Route
                path={getLink(pathTitles.YOURPUPPYSTRAINING)}
                element={<YourPuppysTraining />}
              />
              <Route
                path={getLink(pathTitles.PUPPYDELIVERY)}
                element={<PuppyDelivery />}
              />
              <Route
                path={getLink(pathTitles.CONTACTUS)}
                element={<ContactUs />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
