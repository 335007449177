import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import todoImageLg from "../../Assets/StockPhotos/logo_large.png";
import todoImage from "../../Assets/StockPhotos/logo_small.png";

export const Logo = () => {
  return (
    <Container className="logo_container">
      <span className="logo_helper"></span>
      <Link to="/" className="logo_link">
        <span className="logo-lg">
          <img
            src={todoImageLg}
            className="logo"
            alt="todos translator logo large"
          />
        </span>
        <span className="logo-sm">
          <img
            src={todoImage}
            className="logo"
            alt="todos translator logo small"
          />
        </span>
      </Link>
    </Container>
  );
};
