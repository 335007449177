import React, { useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Store
import { pathTitles, useStore } from "../../Store/Store";
// Components
import { ArrowRight, Interested } from "../Common";

export const PuppyHome = () => {
  const getLink = useStore((state) => state.getLink);
  const puppies = useStore((state) => state.puppies);

  const [amountToLoad, setAmountToLoad] = useState(8);
  const [showButton, setShowButton] = useState(true);
  const handleClick = () => {
    if (amountToLoad < puppies.length) {
      setAmountToLoad((state) => state + 8);
    }
    if (amountToLoad + 8 > puppies.length) {
      setShowButton(false);
    }
  };
  return (
    <>
      <Container className="puppy_home_container small_container clear">
        <Row className="titles">
          <Card.Title className="title">Trained Puppies for Sale</Card.Title>
          <Card.Subtitle className="title two">View Our Puppies</Card.Subtitle>
        </Row>
        <Row>
          <div className="view_puppies">
            {puppies.map((puppy, i) => {
              if (i < amountToLoad) {
                return (
                  <Col
                    key={i}
                    sm={{ span: 10 }}
                    md={{ span: 5 }}
                    lg={{ span: 3 }}
                    className="col"
                  >
                    <Card
                      className="card_inner"
                      as={Link}
                      to={`${getLink(pathTitles.VIEWPUPPIES)}${
                        puppy.profilePath
                      }`}
                    >
                      <span className={`puppy_image profile ${puppy.image}`} />
                      <div className="header_div">
                        <Card.Header className="card_header">
                          {puppy.cardHeaderTitle}
                        </Card.Header>
                        <ArrowRight className="arrow_right" />
                      </div>
                    </Card>
                  </Col>
                );
              }
            })}
          </div>
          {showButton && (
            <Button className="almond" onClick={handleClick}>
              View More...
            </Button>
          )}
        </Row>
      </Container>
      <Interested />
    </>
  );
};
